/* * * * * * * * * * * Variables * * * * * * * * * * */
$main-toolbar-header-background: #dfe1e5;
$container-background-color: white;
$primary-color: #ff7000;
$secondary-color-dark: #606060;
$secondary-color-light: #ebebeb;
$third-color: #496b87;
$border-color: rgb(0, 0, 0); 
:root {
  --main-toolbar-header-background: #{$main-toolbar-header-background};
  --container-linear-background: linear-gradient(#2f6baf77, rgba(114, 146, 24, 0.244));
  --container-background-color: #{$container-background-color};
  --primary-color: #{$primary-color};
  --secondary-color-dark: #{$secondary-color-dark};
  --secondary-color-light: #{$secondary-color-light};
  --third-color: #{$third-color};
  --nav-toolbar-height: 50px;
  --app-text-police: 'Source Sans Pro', sans-serif;
  --border-radius-small: 5px;
  --border-radius-medium: 15px;
  --border-color: #{$border-color};
}

/* * * * * * * * * * * App * * * * * * * * * * */
html,
body {
  margin: 0px;
  min-height: 100% !important;
  height: 100%;
  overflow: hidden;
  font-family: var(--app-text-police);
}
.orderDetailStyle {
  border-radius: 15px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.button-scan .dx-button-content .dx-icon {
  font-size: 100px;
}

.dx-button-text {
  font-size: large;
}
.qr-code-style {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

::ng-deep .returnButtonStyle {
  .dx-icon {
    color: #000000 !important;
  }
}

.default-layout-style {
  height: 100%;
  padding: 10px 20px !important;
}

.dx-scrollable-content {
  min-height: 100% !important;
  height: 100% !important;
}

.information-header {
  background-color: var(--secondary-color-light) !important;
  height: 40px;
  padding: 20px;
}

.information-header-detail {
  background-color: var(--secondary-color-light) !important;
  height: 60px;
  padding: 20px;
}

.check-in-header {
  background-color: var(--secondary-color-dark);
  height: 40px;
  padding: 20px;
  float: right;
  color: white !important;
}

.participant-details {
  margin: 30px 0px 40px 0px;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  height: 120px;
  background-color: white;
  color: black;
  border-radius: var(--border-radius-small);
}